<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import WSManager from '@/modules/ws_data_module';
  import CancellationPolicyPrototype from '@/prototypes/CancellationPolicyPrototype';

  export default {
    extends: CancellationPolicyPrototype,
    data() {
      return {
        applyParams: '',
        supportPortalLink: this.$getString('domainData', 'supportPortalLink')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsOrgInterpreter'
      ]),
      ...mapGetters({
        seriesJobs: 'OneAssignmentStore/seriesJobsForSupplier'
      }),
      ...mapGetters('OneAssignmentStore', [
        'isSameSupplierSeriesJob'
      ]),
      ...mapState('AssignmentFlowStore', {
        seriesJobConfirmation: ({seriesJobConfirmation}) => seriesJobConfirmation,
        seriesJobConfirmationValidationName: ({errors}) => errors.seriesJobConfirmation.name
      }),
      ...mapState('s_DashboardStore', {
        feedbackJobs: (state) => state.feedbackJobs || []
      }),
      ...mapState('EditProfileStore', ['profileData']),
      ...mapState('OneAssignmentStore', {
        storeJobObj: (state) => state.storeJobObj || {},
        discussionStatus: (state) => {
          return state.chatDiscussions && state.chatDiscussions.discussion ? state.chatDiscussions.discussion.status : '';
        },
        discussionId: (state) => {
          return state.chatDiscussions && state.chatDiscussions.discussion ? state.chatDiscussions.discussion.id : '';
        },
        demanderInfo: (state) => {
          return state.chatDiscussions && state.chatDiscussions.discussion ? state.chatDiscussions.discussion.demanderInfo || {} : {};
        },
        isPrivateJob: (state) => {
          const events = state.chatDiscussions ? state.chatDiscussions.discussion?.events : '';
          if (events) {
            for (const event of events) {
              if (event.type === 'invite' || event.type === 'direct_invite') {
                return true;
              }
            }
          }
        }
      }),
      seriesJobConfirmationText() {
        const template = this.$gettext('I understand that I will apply for all <span class="apply-actions__highlighted-text">%{seriesJobsNumber}</span> assignments');

        return this.$gettextInterpolate(template, {
          seriesJobsNumber: this.seriesJobs.length
        });
      },
      assignmentRequirements() { return this.storeJobObj.interpretationRequirement || {}; },
      priceRequirement() { return this.storeJobObj.priceRequirement || {}; },
      jobStatus() { return this.storeJobObj.status || ''; },
      provider() { return this.storeJobObj.provider || ''; },
      isPoliceJob() { return this.storeJobObj.isPolice || false; },
      notCancelledJob() { return this.jobStatus !== 'cancelled'; },
      isSuitableJob() { return this.jobStatus !== 'unsuitable'; },
      isProactiveJob() { return this.jobStatus === 'proactive'; },
      appliedStatus() { return this.discussionStatus === 'applied'; },
      notAppliedStatus() {
        return this.discussionStatus === 'opened'
          || this.discussionStatus === 'invited'
          || this.discussionStatus === 'declined';
      },
      canReApply() {
        return (this.jobStatus === 'withdrawn' && this.discussionStatus === 'withdrawn')
          || (this.jobStatus === 'published' && this.discussionStatus === 'abandoned');
      },
      availableJob() { return this.jobStatus === 'published' || this.jobStatus === 'invited'; },
      canAbandon() { return (this.isPrivateJob && this.discussionStatus !== 'abandoned') || this.isProactiveJob; },
      dialect() { return this.assignmentRequirements.dialect; },
      languageToId() { return this.assignmentRequirements.languageToId; },
      assignmentType() { return this.assignmentRequirements.sessionType; },
      helpArticleLink() { return this.storeJobObj.helpArticle || ''; },
      helpArticleText() {
        const template = this.$gettext('How to invoice<br/><a target="_blank" class="sk-link sk-link--gray-bg" href="%{link}">Terms of this agreement</a>');
        return this.$gettextInterpolate(template, {link: this.helpArticleLink});
      },
      // Job type conditions
      phoneJob() { return this.assignmentType === 'phone'; },
      videoJob() { return this.assignmentType === 'video'; },
      videoroomJob() { return this.assignmentType === 'videoroom'; },
      inPersonJob() { return this.assignmentType === 'in_person'; },
      // shared Apply block condition
      isApplyBlock() {
        return this.userIsInterpreter
          && (this.canReApply || (this.notCancelledJob && this.availableJob && this.notAppliedStatus) || this.isProactiveJob);
      },
      isAppliedBlock() {
        return this.userIsInterpreter
          && this.isSuitableJob
          && this.notCancelledJob
          && this.appliedStatus;
      },
      isTravelCostsBlock() {
        const isInPersonJob = !this.phoneJob
          && !this.videoJob
          && this.isTravelCostsEnabled;
        const inPersonJobInSeries = this.seriesJobs.find((job) => ['in_person', 'videoroom'].includes(job.sessionType)) || {};
        const isJobWithInPersonInSeries = this.isSameSupplierSeriesJob
          && inPersonJobInSeries.travelCost;

        return isInPersonJob || isJobWithInPersonInSeries;
      },
      isTravelCostsEnabled() {
        return this.priceRequirement.travelCost;
      },
      allowRegularTravelCost() {
        return this.priceRequirement.allowedTravelTypes
          && this.priceRequirement.allowedTravelTypes.length === 1
          && this.priceRequirement.allowedTravelTypes[0] === 'default';
      },
      // travel costs apply conditions
      showTravelCosts() {
        return this.isApplyBlock && this.isTravelCostsBlock;
      },
      showTravelCostApplied() {
        return this.isAppliedBlock && this.isTravelCostsBlock;
      },
      showTravelRates() {
        return this.showTravelCosts && !this.allowRegularTravelCost;
      },
      showTravelCostApply() {
        return this.showTravelCosts && this.allowRegularTravelCost;
      },
      // regular apply conditions
      showRegularApply() {
        return this.isApplyBlock && !this.isTravelCostsBlock;
      },
      showRegularApplied() {
        return this.isAppliedBlock && !this.isTravelCostsBlock;
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      permissionModalTitle() {
        const domain = this.provider === 'salita' ? 'Salita' : 'Tikktalk';
        const template = this.$gettext('%{domain} has been chosen as the supplier for interpretation services to the police under a national government tender agreement.');
        return this.$gettextInterpolate(template, {domain});
      }
    },
    methods: {
      ...mapMutations('AssignmentFlowStore', [
        'setSeriesJobConfirmation',
        'setError',
        'removeOneError'
      ]),
      goToDashboard() {
        this.$router.push({name: 'ServerHome'});
      },
      makeProactiveApply() {
        const params = {
          job_id: this.$route.params.id,
          ...this.applyParams
        };

        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/makeProactiveApply', {params}).then((data) => {
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          const errorText = `<div>
          <p class="error-text-item">${this.$gettext('We apologize for the inconvenience.')}</p>
          <p class="error-text-item">${this.$gettext('This assignment was made available to all our preferred interpreters. The time period when these assignments can be claimed is limited.')}</p>
          <p class="error-text-item">${this.$gettext('Unfortunately it can no longer be claimed without a direct invitation.')}</p>
          </div>`;

          if (error.status == 401 || error.status == 422) {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'confirmation-modal',
                classes: ['sk-modal--new'],
                disableClose: true,
                data: {
                  title: this.$gettext('The assignment can no longer be claimed'),
                  text: errorText,
                  context: this,
                  modalCallback: this.goToDashboard,
                  btnTexts: {
                    actionBtnText: this.$gettext('Go back')
                  },
                  visibility: {
                    hideCancelBtn: true
                  }
                }
              });
            }, 0);
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          }
        });
      },
      makeApply() {
        let params;
        let promiseName = '';

        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        if (this.isSameSupplierSeriesJob && this.seriesJobs.length > 1) {
          promiseName = 'OneAssignmentStore/makeSeriesApply';
          params = [];
          this.seriesJobs.forEach((job) => {
            const applyParams = ['in_person', 'videoroom'].includes(job.sessionType)
              ? this.applyParams
              : {};

            params.push({
              job_id: job.id,
              ...applyParams
            });
          });
        } else {
          promiseName = 'OneAssignmentStore/makeApply';
          params = {
            job_id: this.$route.params.id,
            ...this.applyParams
          };
        }
        this.$store.dispatch(promiseName, {id: this.discussionId, params}).then((data) => {
          WSManager.setDataFromSockets(this, data); // trigger 'OneAssignmentStore/getJobById'
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          const errors = error?.data?.errors;
          if (errors?.job_entity_id?.includes('Jobs without feedback')) {
            this.$store.dispatch('s_DashboardStore/getFeedbackJobs').then(() => {
              this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
              this.$store.dispatch('ModalStore/closeModal');
              setTimeout(() => {
                this.openFeedbackJobsModal();
              });
            });
            return;
          }
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.dispatch('ModalStore/closeModal');
          if (errors?.suitable_assignment?.includes('Assignment is not suitable anymore')) {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'unsuitable-job-modal',
                classes: ['unsuitable-modal'],
                data: {
                  title: this.$gettext('This assignment is no longer available')
                },
                disableClose: true,
                width: 410
              });
            });
            return;
          }
          if (errors?.appliable?.includes('Already applied')) {
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('You already applied to this invitation.'),
                method: 'modalCallback',
                context: this
              });
            });
            return;
          }
          if (errors?.job_id && errors.job_id.length) {
            if (errors.job_id.includes('Can\'t attend call without video attested')) {
              setTimeout(() => {
                this.$store.commit('ModalStore/setModal', {
                  component: 'video-test-apply-error-modal',
                  data: {title: this.$gettext('To apply for this assignment you have to first pass the video test')}
                });
              }, 0);
              return;
            }
            if (errors.job_id.includes('police_confidential') || errors.job_id.includes('police_attested')) {
              setTimeout(() => {
                this.$store.commit('ModalStore/setModal', {
                  component: 'signatures-apply-error-modal',
                  width: 410,
                  classes: ['sk-modal--new'],
                  data: {
                    title: this.$gettext('Signatures required'),
                    context: this,
                    notSignedDocs: errors.job_id
                  }
                });
              }, 0);
              return;
            }
            if (errors.job_id.includes('Can\'t attend in-person job without address')) {
              setTimeout(() => {
                this.$store.commit('ModalStore/setModal', {
                  component: 'address-error-modal',
                  width: 400,
                  disableClose: true,
                  data: {context: this}
                });
              }, 0);
              return;
            }
          }
          if (errors?.job_entity_id?.includes('Default payout method required')) {
            this.$store.commit('ModalStore/setModal', {
              component: 'payout-info-modal',
              width: 600,
              data: {
                context: this,
                callback: 'openPriceTmpModal'
              }
            });
            return;
          }
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      },
      modalCallback() {
        window.location.reload();
      },
      sendAbandon() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/makeAbandon', this.$route.params.id).then(() => {
          this.$router.push(this.assignmentsLink);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          if (error?.data?.errors?.includes('Unallowed to abandon')) {
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('You have already declined this invitation.'),
                method: 'modalCallback',
                context: this
              });
            }, 0);
          }
        });
      },
      openPermissionModal(applyModalParams) {
        this.$store.commit('ModalStore/setModal', {
          component: 'permission-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.permissionModalTitle,
            context: this,
            applyModalParams
          }
        });
      },
      openFeedbackJobsModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'feedback-jobs-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Please provide feedback')
          }
        });
      },
      openDialectModal(params) {
        this.$store.commit('ModalStore/setModal', {
          component: 'dialect-request-modal',
          width: 610,
          classes: ['sk-modal--new'],
          data: {
            context: this,
            title: this.$gettext('Special Dialect Request'),
            dialect: this.dialect,
            languageToId: this.languageToId,
            params: params
          }
        });
      },
      getTravelParams(data) {
        const params = data || {};
        const travelParams = {};
        if (!params.travel_cost) return {};
        if (params.travel_cost.travel_type === 'public_transport') {
          travelParams.travel_duration = params.travel_cost.travel_time;
          travelParams.travel_expenses = params.travel_cost.travel_allowance;
        }
        if (params.travel_cost.travel_type) {
          travelParams.travel_by = params.travel_cost.travel_type;
        }
        return travelParams;
      },
      openPriceTmpModal(params) {
        this.applyParams = params || '';
        const travelParams = this.getTravelParams(params);
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('AssignmentFlowStore/getInterModalInvoicePreviewData', {
          jobId: this.$route.params.id,
          params: travelParams
        }).then(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'apply-assign',
            classes: ['sk-modal--new'],
            data: {
              title: this.$gettext('Confirm and send offer'),
              context: this
            }
          });
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        });
      },
      getUserProfileData() {
        return new Promise((resolve, reject) => {
          if (this.profileData) {
            resolve();
          } else {
            this.$store.commit('OneAssignmentStore/startAssignmentProgress');
            this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.$store.getters['UserInfoStore/userUid']}).then(() => {
              this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
              resolve();
            }).catch((error) => {
              reject(error);
            });
          }
        });
      },
      handleApply(params) {
        if (this.isProactiveJob) {
          this.handleProactiveApply(params);
        } else {
          this.handleRegularApply(params);
        }
      },
      handleProactiveApply(params) {
        this.applyParams = params !== undefined ? params : '';

        const startDate = this.$moment(this.storeJobObj.interpretationRequirement.startTime);
        const finishDate = this.$moment(this.storeJobObj.interpretationRequirement.finishTime);
        const deadline = `${startDate.format('DD.MM.YY')} ${startDate.format('HH:mm')} - ${finishDate.format('HH:mm')}`;

        const template = this.$gettext('You accept the assignment time: %{deadline}');
        const modalContent = `<p>
          ${this.$gettext('By continuing you confirm that:')}
          <ul class="confirmation-list">
            <li>${this.$gettext('You have reviewed the information provided in this assignment')}</li>
            <li>${this.$gettext('You are able to complete this assignment')}</li>
            <li>${this.$gettextInterpolate(template, {deadline: deadline})}</li>
          </ul>
        </p>`;

        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Claim assignment'),
            text: modalContent,
            context: this,
            modalCallback: this.makeProactiveApply,
            btnTexts: {
              actionBtnText: this.$gettext('Confirm and claim'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      handleRegularApply(params) {
        this.applyParams = params !== undefined ? params : '';
        this.removeOneError('seriesJobConfirmation');
        this.$store.commit('ErrorsStore/setError', {
          name: this.seriesJobConfirmationValidationName,
          errors: []
        });

        if (this.isSameSupplierSeriesJob && !this.seriesJobConfirmation) {
          this.setError({
            fieldName: 'seriesJobConfirmation',
            errorText: 'required'
          });
          this.$store.commit('ErrorsStore/setError', {
            name: this.seriesJobConfirmationValidationName,
            errors: []
          });
          setTimeout(() => {
            this.$scrollToErrors();
          });
          return;
        }
        if (this.dialect) {
          this.openDialectModal(params);
        } else {
          this.openJobApplyModal(params);
        }
      },
      openJobApplyModal(params) {
        if (this.feedbackJobs.length > 0) {
          this.openFeedbackJobsModal();
        } else if (this.isPoliceJob) {
          this.getUserProfileData().then(() => {
            if (!this.profileData.dataSharingConsentForPolice) {
              this.openPermissionModal(params);
              return;
            }
            this.openPriceTmpModal(params);
          });
        } else {
          this.openPriceTmpModal(params);
        }
      },
      handleAbandon() {
        const awardJSON = {
          title: this.$gettext('Decline offer'),
          context: this
        };
        this.$store.commit('ModalStore/setModal', {
          component: 'abandon-assign',
          data: awardJSON
        });
      }
    }
  };
</script>

<style>
.confirmation__text {
  margin-left: 5px;
}

.confirmation-list {
  margin: 20px 0 0 28px;
}

.error-text-item {
  margin-bottom: 15px;
}
</style>
